<template>
  <div class="pb-5">
    <template v-if="isMobile">
      <v-card class="d-flex align-center justify-space-between mb-4">
        <div class="header-name d-flex align-center">
          <router-link
            to="/callflow"
            class="back-link fz-20"
          >
            <v-icon size="30px" color="#6170C5">
              navigate_before
            </v-icon>
          </router-link>
          <h1 :class="isMobile ? 'font-weight-regular' : 'font-weight-semi-bold'" class="dark-text fz-20 py-3">{{ title }} Callflow</h1>
        </div>
        <div class="header-buttons d-flex align-center">
          <v-btn
            text
            class="text-none d-flex align-center px-0 theme--dark"
            height="36px"
            @click="exportXml"
          >
            <v-icon size="24px" color="#919EAB">file_download</v-icon>
          </v-btn>
          <v-btn
            :disabled="!callflowName.trim() || hasError || isCreatingCallFlow"
            text
            class="text-none d-flex align-center px-0 font-weight-bold"
            height="36px"
            color="#5C6AC4"
            @click="submit"
          >
            Save
          </v-btn>
        </div>
      </v-card>
    </template>
    <template v-else>
      <router-link
        to="/callflow"
        class="back-link mt-4"
      >
        <v-icon size="24px">
          navigate_before
        </v-icon>
        Callflows
      </router-link>
      <div class="d-flex align-center justify-space-between">
        <h1 class="dark-text font-weight-semi-bold fz-28 mb-4">{{ title }} callflow</h1>
      </div>
    </template>

    <v-card
      class="transparent px-2 px-md-0"
      elevation="0"
      max-width="730px"
      v-resize="calculateLines"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-form>
            <v-timeline
              dense
              clipped
              class="pa-0"
              align-top
            >
              <!--START CALL FLOW-->
              <v-timeline-item
                fill-dot
                color="#5C6AC4"
                icon="call_split"
                class="v-timeline-item__no-move__dot"
              >
                <v-col
                  v-if="title === 'Create'"
                  cols="12"
                  md="12"
                  class="py-0 pl-4 pr-0 pb-6"
                >
                  <div :class="isMobile ? 'fz-20 font-weight-medium' : 'fz-26'" class="dark-text">Start Callflow</div>
                </v-col>

                <!-- <v-col
                  cols="12"
                  md="12"
                  class="pt-2 pb-6 pl-4 pr-0"
                >
                  <div class="grey--text fz-14">
                    Build a new callflow below or
                    <a
                      style="color: #007ACE; cursor: pointer;"
                      @click="assignUser(assignUserPopUp = !assignUserPopUp)"
                    >
                      duplicate steps from an existing callflow
                    </a>
                  </div>
                </v-col> -->

                <v-col
                  cols="12"
                  md="6"
                  class="pt-0 pb-6 pl-4 pr-0"
                >
                  <label class="fz-14 mb-1 d-block">Callflow name</label>
                  <v-text-field
                    v-model="callflowName"
                    :maxlength="60"
                    solo
                    outlined
                    type="text"
                    dense
                    hide-details
                    :height="isMobile ? '48px' : '36px'"
                    :class="{ 'max-w-callflow': isXS }"
                    @keypress="onlyAlphanumeric"
                  ></v-text-field>
                </v-col>
              </v-timeline-item>

              <!--CHECKBOX RECORD CALL-->
              <v-timeline-item
                fill-dot
                color="#919EAB"
                icon="mic"
                class="v-timeline-item__no-move__dot timeline-caller timeline-mic"
              >
                <v-col
                  cols="12"
                  md="4"
                  :class="['pt-0', 'pl-4', 'pr-0', 'record-checkbox',
                    isMobile ? 'pb-0' : 'pb-6'
                  ]"
                >
                  <!-- <v-checkbox
                    v-model="form.recordNotify"
                    label="Record calls"
                    class="checkbox mt-1"
                  ></v-checkbox> -->
                  <div class="mt-1 fz-16 black--text">Record calls</div>
                  <div class="fz-14" style="color: #637381;">Do I need to notify the caller?</div>
                </v-col>
                <v-col
                  v-if="form.recordNotify"
                  cols="12"
                  md="5"
                  :class="{ 'pl-4': isMobile }"
                >
                <label class="switch mt-3">
                  <input
                    v-model="recordCalls"
                    type="checkbox"
                  >
                  <span class="slider"></span>
                  <div class="knobs"></div>
                </label>
                </v-col>
              </v-timeline-item>

              <template
                v-for="(component, index) in steps"
              >
                <component
                  v-if="component.isVisible && showComponent(component)"
                  :key="index"
                  :is="component.name"
                  :index="index"
                  :hangUpOption="!isCallEnded"
                  :path="[index]"
                  @insertStepComponent="insertStep"
                  @deleteStepIndex="deleteStep(component, index)"
                />
              </template>
              <v-timeline-item
                v-if="!isCallEnded"
                fill-dot
                color="#5C6AC4"
                :icon="isVisible ? 'remove' : 'add'"
                class="v-timeline-item__no-divider pointer"
                :class="[!isVisible ? 'max-width' : '']"
                @click.native.stop="isVisible = !isVisible"
              >
                <CallflowStepMenu
                  v-if="!isCallEnded"
                  :isVisible="isVisible"
                  @insertStepName="insertStep"
                />
              </v-timeline-item>
            </v-timeline>
          </v-form>
        </v-col>
      </v-row>
      <vue-snotify />
    </v-card>
    <header-buttons
      v-if="!isMobile"
      :disabled="!callflowName.trim() || hasError || isCreatingCallFlow || !steps.length"
      has-export
      hide-changes
      export-name="XML"
      :ok="$route.name === 'EditCallflow' ? 'Save' : 'Create'"
      @ok="submit"
      @export="exportXml"
    />
    <v-overlay :value="isCreatingCallFlow">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import CallflowGreeting from '../components/CallflowGreeting'
import CallflowDial from '../components/CallflowDial'
import CallflowSimulcall from '../components/CallflowSimulcall'
import CallflowMenu from '../components/CallflowMenu'
import CallflowSchedule from '../components/CallflowSchedule'
import CallflowGeoRouting from '../components/CallflowGeoRouting'
import CallflowTag from '../components/CallflowTag'
import CallflowVoicemail from '../components/CallflowVoicemail'
import CallflowHangUp from '../components/CallflowHangUp'
import CallflowStepMenu from '../components/CallflowStepMenu'
import HeaderButtons from '@/components/HeaderButtons'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'CreateCallflow',
  components: {
    CallflowGreeting,
    CallflowDial,
    CallflowSimulcall,
    CallflowMenu,
    CallflowSchedule,
    CallflowGeoRouting,
    CallflowTag,
    CallflowVoicemail,
    CallflowHangUp,
    CallflowStepMenu,
    HeaderButtons
  },
  data: () => ({
    tempName: '',
    components: [],
    form: {
      recordNotify: true
    },
    phoneRules: [
      v => !!v || 'Phone number is required',
      v => /^[0-9]+$/.test(v) || 'Phone number must be valid'
    ],
    title: 'Create',
    isVisible: false,
    actionPath: {
      CallflowMenu: ['payload', 'menuDtmf'],
      schedule_branch: ['payload', 'scheduleHours', 'branchs'],
      area_code: ['payload', 'georoutingConfig', 'areaCode'],
      zip_code: ['payload', 'georoutingConfig', 'zipCode']
    }
  }),
  computed: {
    ...mapState('components', [
      'rightPopUpMyContacts'
    ]),
    ...mapGetters('callflow', ['steps', 'getFromData', 'hasError', 'isCreatingCallFlow', 'hasHangUp']),
    isCallEnded () {
      return this.hasHangUp
    },
    assignUserPopUp: {
      get () {
        return this.rightPopUpMyContacts
      },
      set (videoValue) {
        return videoValue
      }
    },
    callflowName: {
      get () { return this.getFromData('name') },
      set (data) { this.setCallflowDataStep({ step: 'name', data }) }
    },
    recordCalls: {
      get () { return this.getFromData('enableRecording') },
      set (data) { this.setCallflowDataStep({ step: 'enableRecording', data }) }
    },
    didNumbers () {
      return this.getFromData('didNumbers')
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isXS () {
      return this.$vuetify.breakpoint.xs
    },
    changed () {
      return this.tempName !== this.getFromData('name')
    }
  },
  created () {
    this.resetCallflow()
    if (this.$route.name === 'EditCallflow') {
      this.title = 'Edit'
      this.loadCallflow()
    } else if (this.$route.name === 'CreateCallflow' && this.$route.params.uuid) {
      this.loadCallflow()
    } else {
      this.setNumber()
    }
  },
  methods: {
    ...mapActions(['getCallflow']),
    ...mapActions('callflow', [
      'setCallflowDataStep',
      'getCallflow',
      'getCallflowSteps',
      'addStep',
      'submitCallFlow',
      'submitCallFlowSteps',
      'generateXml',
      'assignDidToCallflow',
      'calculateLines'
    ]),
    ...mapMutations('callflow', ['resetCallflow', 'pushToStep', 'removeFromStep', 'setStepValue']),
    setNumber () {
      if (this.$route.params.number) {
        this.setCallflowDataStep({ step: 'didUuid', data: this.$route.params.number })
      }
    },
    async loadCallflow () {
      try {
        const { data } = await this.getCallflow(this.$route.params.uuid)
        this.setCallflowDataStep({ step: 'name', data: data.payload.name })
        this.tempName = data.payload.name
        // this.setCallflowDataStep({ step: 'enableRecording', data: data.payload.enableRecording })
        this.recordCalls = this.getFromData('enableRecording')
        this.setNumber()
        // await this.getCallflowSteps(this.$route.params.uuid)
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.calculateLines()
        }, 200)
      }
    },
    insertStep (component) {
      let parent = null
      const id = Date.now()
      const index = component.index !== undefined ? component.index : this.steps.length
      let parentId = -1
      if (component.parent !== undefined) {
        parentId = component.parent
        parent = this.steps[parentId].id
        console.log(parent, 'parentId')
        this.pushToStep({ path: [parentId].concat('children'), value: id })
      }
      const place = index + (parentId >= 0 ? parentId : 0)
      this.addStep({ path: [place], name: component.name, parent, id })
      setTimeout(() => {
        this.calculateLines()
      }, 200)
    },
    deleteStep (component, index) {
      if (component.children.length) {
        component.children.forEach(idx => {
          const child = this.steps.findIndex(e => e.id === idx)
          if (child >= 0) {
            this.removeFromStep({ path: [child] })
          }
        })
      }
      if (!component.parent) {
        this.removeFromStep({ path: [index] })
        setTimeout(() => {
          this.calculateLines()
        }, 200)
        return
      }
      const parent = this.steps.find(e => e.id === component.parent)
      const parentIndex = this.steps.findIndex(e => e.id === component.parent)
      if (!parent || parentIndex < 0) return
      const childIndex = parent.children.findIndex(e => e === component.id)
      if (childIndex < 0) return
      let action = -1
      let branch = 'georoutingAreaCode'
      switch (parent.name) {
        case 'CallflowMenu':
          this.removeFromStep({
            path: [parentIndex].concat('payload', 'menuDtmf', childIndex)
          })
          break
        case 'CallflowSchedule':
          if (parent.actions.other.id === component.id) {
            this.setStepValue({
              path: [parentIndex].concat('actions', 'other'),
              value: { name: '', type: -1 }
            })
            break
          }
          action = parent.actions.branches.findIndex(e => e.id === component.id)

          if (action < 0) break
          this.removeFromStep({
            path: [parentIndex].concat('actions', 'branches', action)
          })
          break
        case 'CallflowGeoRouting':
          if (parent.actions.other.id === component.id) {
            this.setStepValue({
              path: [parentIndex].concat('actions', 'other'),
              value: { name: '', type: -1 }
            })
            break
          }
          action = parent.actions[branch].findIndex(e => e.id === component.id)
          if (action < 0) {
            branch = 'georoutingZip'
            action = parent.actions[branch].findIndex(e => e.id === component.id)
          }
          if (action < 0) break
          this.removeFromStep({
            path: [parentIndex].concat('actions', branch, action)
          })
          break
      }
      this.removeFromStep({
        path: [parentIndex].concat('children', childIndex)
      })
      this.removeFromStep({ path: [index] })
      setTimeout(() => {
        this.calculateLines()
      }, 200)
    },
    async submit () {
      this.$snotify.notifications = []
      try {
        const isEdit = this.$route.name === 'EditCallflow'

        const { data } = await this.submitCallFlow({ isEdit, uuid: this.$route.params.uuid, changed: this.changed })
        if (!data || !data.success) return
        if (this.$route.params.number) {
          if (!await this.assignNumberToCallflow(data.payload.uuid)) {
            return this.$snotify.error('DID is already assigned with one callflow')
          }
        }
        this.$snotify.success('Successful')
        setTimeout(() => {
          this.$router.push({ name: 'Callflow' })
        }, 2000)
      } catch (e) {
        let message = Array.isArray(e.message) ? e.message[0] : e.message
        message = message[0].toUpperCase() + message.substring(1)
        this.$snotify.error(message)
        throw new Error(e)
      }
    },
    async assignNumberToCallflow (uuid) {
      try {
        await this.assignDidToCallflow({
          didUuid: this.$route.params.number,
          callflowUuid: uuid
        })
        return true
      } catch (err) {
        if (err.statusCode === 400) return false
        throw new Error(err)
      }
    },
    assignUser (payload) {
      this.$store.dispatch('components/RightPopUpMyContacts', payload)
    },
    async exportXml () {
      const xml = await this.generateXml()
      var blob = new Blob([xml], { type: 'text/xml;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, 'callflow')
      } else {
        var link = document.createElement('a')
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', 'callflow')
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
    showComponent (component) {
      if (!component.parent) return true
      const parent = this.steps.find(e => e.id === component.parent)
      if (!parent || parent.name !== 'CallflowGeoRouting') return true
      return component.message.type === 'geoRouting' &&
        (component.message.branch === 'other' ||
        (!parent.activeTab && component.message.branch === 'georoutingAreaCode') ||
        (parent.activeTab && component.message.branch === 'georoutingZip'))
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-caller ::v-deep .v-timeline-item__body {
  display: flex;
}

.export-xml {
  border: 2px solid #353738;
  padding: 5px 10px;
  cursor: pointer;
}

.max-width {
  width: max-content;
}
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}
.knobs{
  position: absolute;
  inset: 0;
}
.knobs::before{
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 6px;
  z-index: 2;
  font-weight: 600;
}
input:checked ~ .knobs::before{
  content: 'Yes';
  left: 5px;
}
input:not(checked) ~ .knobs::before{
  content: 'No';
  right: 6px;
}
.record-checkbox ::v-deep .v-label {
  color: black !important;
  font-weight: 500;
}

.max-w-callflow {
  max-width: 274px;
}

::v-deep .actions-row {
  position: relative;
}
::v-deep .toggle-action {
  position: absolute;
  right: -60px;
  margin-top: 6px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #919EAB;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: all 0s;
}
::v-deep .geo-actions .toggle-action {
  right: -72px;
  margin-top: 0;
}
::v-deep .geo-actions .toggle-action-wrapper hr {
  margin-left: 72px;
  margin-top: 0;
}
::v-deep .geo-actions .toggle-action-wrapper hr.horizontal-line-bottom {
  margin-left: 74px !important;
}
::v-deep .toggle-action::before {
  position: absolute;
  content: '';
  width: 23px;
  left: -23px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background: #919EAB;
  pointer-events: none;
  opacity: 1;
}
::v-deep .toggle-action:hover::before {
  opacity: 1 !important;
}

.timeline-mic ::v-deep .v-timeline-item__inner-dot {
  padding-bottom: 5px;
  &::after, &::before {
    content: '';
    position: absolute;
    background: #fff;
    z-index: 2;
    left: 50%;
    translate: -50% 0;
  }
  &::after {
    width: 10px;
    height: 2px;
    top: 28px;
  }
  &::before {
    width: 2px;
    height: 3px;
    top: 25px;
  }
}

@media screen and (max-width: 575px) {
  .timeline-caller ::v-deep .v-timeline-item__body {
    flex-direction: column;
  }
}
</style>
